import request from "@/utils/request";

// 查询所在支部下拉列表
export function listDangzhibuData() {
  return request({
    url: "/szdt/wjdt/grid/list",
    method: "get",
  });
}

// 查询所在支部组织架构
export function getListjiagouDetail(dataId) {
  return request({
    url: "/szdt/wjdt/member/list?gridId=" + dataId,
    method: "get",
  });
}

// 获取网格成员列表
export function getMemberList(data) {
  return request({
    url: "/szdt/wjdt/member/list",
    method: "get",
    params: data,
  });
}

// 新增
export function postDataDetail(data) {
  return request({
    url: "/szdt/wjdt/member",
    method: "post",
    data,
  });
}

// 批量修改
export function batchDataDetail(data) {
  return request({
    url: "/szdt/wjdt/member",
    method: "put",
    data: data,
  });
}

export function deleteDataDetail(ids) {
    return request({
      url: `/szdt/wjdt/member/${ids}`,
      method: 'delete',
    })
  }

  
export const getFileName = (params) => {
    return request({
        url: '/szdt/wjdt/member/importTemplete',
        method: 'get',
        params: params
    })
}

export function importData(data) {
    return request({
        url: '/szdt/wjdt/member/importData',
        method: 'post',
        data
    })
}