<template>
  <div>
    <div class="container">
      <!-- 背景 -->
      <img
        class="back-ground-image"
        src="@/assets/image/dangyuan-mc-bg-red.png"
        alt=""
      />
      <!-- 顶部 -->
      <div class="header"><img class="header-logo" src="../../assets/image/wjlogo.png" /> 旺家·东泰共建数字社区</div>
      <!-- 返回 刷新 -->
      <rightNav></rightNav>
      <!-- 时间 -->
      <div class="time-box">
        <Date></Date>
      </div>
      <!-- 内页标题 -->
      <div class="page-title">网格组织架构</div>
      <!-- top -->
      <div class="member-top">
        <!-- <div class="member-top-1">网络组织架构</div> -->
        <div class="member-top-2">
          <div class="member-top-2L">
            <el-form ref="form" :model="form">
              <!-- 请选择所在支部 -->
              <el-select
                v-model="value"
                placeholder="请选择所在网格"
                @change="changezhou($event)"
              >
                <el-option
                  v-for="(item, index) in dataOptions"
                  :key="index"
                  :label="item.name"
                  :value="item.gridId"
                >
                </el-option>
              </el-select>
              <!-- <el-button type="primary" @click="onSubmit">查询</el-button> -->
            </el-form>
          </div>
          <div style="margin-right:5%" v-if="$cookies.get('pass') === 'wangjia456'">
              <el-button  type="primary" @click="$router.push({ path: '/wjawards' })">编辑</el-button>
          </div>
          <!-- <div class="member-top-2R">
            <span class="dwjs-btn" @click="handleCount">
              <img class="dwjs-btn-1" src="@/assets/image/dy-right-btn.png" />
              <img
                class="dwjs-btn-2 dwjs-btn-4"
                src="@/assets/image/dy-icon-2.png"
              />
              <span class="dwjs-btn-3 dwjs-btn-5">数据统计</span>
            </span>
          </div> -->
        </div>
        <div class="member-top-3">
          <div class="member-top-3-1">
            {{ gridTitleInfo.name }} ({{ gridTitleInfo.manageDesc }})
          </div>
          <div class="member-top-3-2">
            {{ gridTitleInfo.number1 }}户 {{ gridTitleInfo.number2 }}人
          </div>
          <div
            class="member-top-3-3"
            v-loading.fullscreen.lock="loading"
            element-loading-text="拼命加载中"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          >
            <div class="member-jiagou">
              <!-- 党支部书记 -->
              <div
                class="member-jiagou-1"
                style="height: 90px"
                v-if="dataFramework1.length > 0"
              >
                <div
                  class="member-jiagou-btn"
                  v-for="(item, index) in dataFramework1"
                  :key="index"
                  @click="dialogVisiblebtn(item)"
                >
                  <img class="member-img" :src="getbgImgUrl(item.zzmm)" />
                  <img class="markIcon" v-if="item.zzmm === '党员' || item.zzmm === '预备党员'" src="@/assets/icon/dh.png" alt="">
                  <img class="markIcon" v-if="item.flowersFlag === '1'" src="@/assets/icon/fl.png" alt="">
                  <!-- :src="getbgImgUrl(item.age, item.partyAge, item.sex)" -->
                  <div class="member-box">
                    <span
                      :class="
                        getsex_age(item.sex, item.age)
                          ? 'member-name0'
                          : 'member-name1'
                      "
                      >{{ item.title }}</span
                    >
                    <span class="member-name2">{{ item.realName }}</span>
                  </div>
                  <div class="member-jiagou-gan"></div>
                </div>
              </div>
              <div class="clear"></div>
              <!-- 党支部副书记 -->
              <!-- <div
                class="member-jiagou-1 member-jiagou-2"
                v-if="dataFramework2.length > 0"
              >
                <div
                  class="member-jiagou-btn"
                  v-for="(item, index) in dataFramework2.slice(0, 2)"
                  :key="index"
                  @click="dialogVisiblebtn(item.id, item.partyUnitId)"
                >
                  <img
                    class="member-img"
                    :src="getbgImgUrl(item.age, item.partyAge, item.sex)"
                  />
                  <div class="member-box">
                    <span
                      :class="
                        getsex_age(item.sex, item.age)
                          ? 'member-name0'
                          : 'member-name1'
                      "
                      >党支部副书记</span
                    >
                    <span class="member-name2">{{ item.name }}</span>
                  </div>
                  <div class="member-jiagou-gan"></div>
                </div>
              </div>
              <div class="clear"></div> -->
              <!-- 委员 -->
              <!-- <div
                class="member-jiagou-1 member-jiagou-3"
                v-if="dataFramework3.length > 0"
              >
                <div
                  class="member-jiagou-btn"
                  v-for="(item, index) in dataFramework3.slice(0, 7)"
                  :key="index"
                  @click="dialogVisiblebtn(item.id, item.partyUnitId)"
                >
                  <img
                    class="member-img"
                    :src="getbgImgUrl(item.age, item.partyAge, item.sex)"
                  />
                  <div class="member-box">
                    <span
                      :class="
                        getsex_age(item.sex, item.age)
                          ? 'member-name0'
                          : 'member-name1'
                      "
                      >委员</span
                    >
                    <span class="member-name2">{{ item.name }}</span>
                  </div>
                </div>
              </div> -->
              <div class="clear"></div>
              <!-- 党员 -->
              <!-- <div class="member-top-3-1" style="margin: 0 0 30px 0">
                党员名册
              </div> -->
              <div
                class="member-jiagou-1 member-jiagou-4"
                v-if="dataFramework4.length > 0"
              >
                <div
                  class="member-jiagou-btn"
                  v-for="(item, index) in dataFramework4"
                  :key="index"
                  @click="dialogVisiblebtn(item)"
                >
                  <img class="member-img" :src="getbgImgUrl(item.zzmm)" />
                  <img class="markIcon" v-if="item.zzmm === '党员' || item.zzmm === '预备党员'" src="@/assets/icon/dh.png" alt="">
                  <img class="markIcon" v-if="item.flowersFlag === '1'" src="@/assets/icon/fl.png" alt="">
                  <div class="member-box">
                    <span
                      :class="
                        getsex_age(item.sex, item.age)
                          ? 'member-name0'
                          : 'member-name1'
                      "
                      >{{ item.title }}</span
                    >
                    <span class="member-name2">{{ item.realName }}</span>
                  </div>
                  <!-- <div class="member-jiagou-gan"></div> -->
                </div>
              </div>
              <div v-else style="text-align: center; color: #fff">暂无数据</div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 弹框 -->
      <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="35%"
        top="300px"
        :append-to-body="true"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div class="dialog-bg">
          <img
            class="back-ground-image"
            src="@/assets/image/dy-bg.png"
            alt=""
          />
          <!-- 关闭 -->
          <img
            class="back-ground-close"
            src="@/assets/image/btn-close8.png"
            alt=""
          />
        </div>
        <!--  -->
        <div class="dialog-box">
          <div class="dialog-box-top">
            <div class="dialog-box-top-L">
              <img
                class="tx-img"
                v-if="userInfo.gender == '0'"
                src="@/assets/image/dy-img-1.png"
                alt=""
              />
              <img
                class="tx-img"
                v-else
                src="@/assets/image/dy-img-0.png"
                alt=""
              />
              <div class="dialog-box-top-L2">{{ userInfo.realName }}</div>
              <!-- <div class="dialog-box-top-L3" v-if="dataMask.params">
                {{ dataMask.params.partyUnitName }}
              </div> -->
              <!-- <div class="dialog-box-top-L3" v-if="dataMask.params">
                {{ dataMask.params.partyUnitName }}
              </div> -->
            </div>
            <!-- 所在支部 所在单位 民族 籍贯 出生年月 身份证号 家庭地址 备注 -->
            <!-- 东莞市民盈房地产开发有限公司党支部 东莞市民盈房地产开发有限公司 汉族 广东东莞 1980-09-19 441900***** -->
            <div class="dialog-box-top-R">
              <div class="dialog-box-top-R-odd">
                <div class="dialog-box-top-R-odd-L">
                  <span>姓名</span>
                  <span>{{ userInfo.realName }}</span>
                </div>
              </div>
              <div class="dialog-box-top-R-even">
                <div class="dialog-box-top-R-odd-L">
                  <span>政治面貌</span>
                  <span>{{ userInfo.zzmm }}</span>
                </div>
              </div>
              <div class="dialog-box-top-R-odd">
                <div class="dialog-box-top-R-odd-L">
                  <span>职务</span>
                  <span>{{ userInfo.title }}</span>
                </div>
              </div>
              <div class="dialog-box-top-R-even">
                <div class="dialog-box-top-R-odd-L">
                  <span>电话</span>
                  <span>{{ userInfo.phone }}</span>
                </div>
              </div>
              <div class="dialog-box-top-R-even">
                <div class="dialog-box-top-R-odd-L">
                  <span>负责区域</span>
                  <span>{{ userInfo.fzArea }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  // getListDetail,
  // getListPersonDetail,
  listIntegral,
  listPayment,
  listPunch,
} from "@/api/partymember";
import { listDangzhibuData, getListjiagouDetail } from "@/api/memberRegister";
import Date from "@/views/commonComponents/date.vue";
import rightNav from "@/views/commonComponents/rightNav.vue";
export default {
  components: {
    Date,
    rightNav,
  },
  data() {
    return {
      gridTitleInfo: {},
      userInfo: {},
      loading: false,

      input: "",
      activeName: "first",
      dialogVisible: false,
      //
      form: {
        date1: "",
        date2: "",
      },
      //
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      //委员
      weiyuan: [
        {
          value: "刘诗",
          label: "委员",
        },
        {
          value: "周冬雨",
          label: "委员",
        },
        {
          value: "李宇琪",
          label: "委员",
        },
        {
          value: "李宇琪",
          label: "委员",
        },
        //   {
        //   value: "刘诗",
        //   label: "委员",
        // },
        // {
        //   value: "周冬雨",
        //   label: "委员",
        // },
        // {
        //   value: "李宇琪",
        //   label: "委员",
        // },
        //  {
        //   value: "李宇琪",
        //   label: "委员",
        // },
      ],
      //党员
      dangyuan: [
        {
          value: "张美芳",
          label: "党员",
        },
        {
          value: "李可可",
          label: "党员",
        },
        {
          value: "张雨绮",
          label: "党员",
        },
        {
          value: "苏哲",
          label: "党员",
        },
        {
          value: "陈敏华",
          label: "党员",
        },
        {
          value: "魏新雨",
          label: "党员",
        },
        {
          value: "李敏",
          label: "党员",
        },
        {
          value: "吴东坡",
          label: "党员",
        },
        {
          value: "张美芳",
          label: "党员",
        },
        {
          value: "李可可",
          label: "党员",
        },
        {
          value: "张雨绮",
          label: "党员",
        },
        {
          value: "苏哲",
          label: "党员",
        },
        // {
        //   value: "陈敏华",
        //   label: "党员",
        // },
        // {
        //   value: "魏新雨",
        //   label: "党员",
        // },
        // {
        //   value: "李敏",
        //   label: "党员",
        // },
        // {
        //   value: "吴东坡",
        //   label: "党员",
        // },
      ],
      // 表格测试<!--index laiyuan date jifen beizhu-->
      userList: [
        {
          laiyuan: "广东东莞",
          date: "2020-01-18",
          jifen: "+10",
          beizhu: "哈哈",
        },
        {
          laiyuan: "广东东莞",
          date: "2020-01-18",
          jifen: "+10",
          beizhu: "哈哈",
        },
        {
          laiyuan: "广东东莞",
          date: "2020-01-18",
          jifen: "+10",
          beizhu: "哈哈",
        },
        {
          laiyuan: "广东东莞",
          date: "2020-01-18",
          jifen: "+10",
          beizhu: "哈哈",
        },
        {
          laiyuan: "广东东莞",
          date: "2020-01-18",
          jifen: "+10",
          beizhu: "哈哈",
        },
        {
          laiyuan: "广东东莞",
          date: "2020-01-18",
          jifen: "+10",
          beizhu: "哈哈",
        },
        {
          laiyuan: "广东东莞",
          date: "2020-01-18",
          jifen: "+10",
          beizhu: "哈哈",
        },
        {
          laiyuan: "广东东莞",
          date: "2020-01-18",
          jifen: "+10",
          beizhu: "哈哈",
        },
      ],
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      dataOptions: [], //所在党支部
      dataFramework1: [], //架构1
      dataFramework2: [], //架构2
      dataFramework3: [], //架构3
      dataFramework4: [], //架构4
      dzbuId: 1,
      dzbName: "",
      setUpDate: "",
      createTime: "2020-01-18",
      dataMask: [], //个人信息
      addressInfo: "", //地址
      phoneInfo: "", //联系电话
      idInfo: "", //地址
      dataPayment: [], //缴费
      dataIntegral: [], //积分
      dataPunch: [], //打卡
      sex: "",
      partyPayrollId: "",
      beginTime: "",
      endTime: "",
      education: "",
    };
  },
  created() {
    this.getDangzhibuData();
  },
  methods: {
    // 判断男女年龄
    getsex_age(sex, age) {
      return (sex == "1" && age > 50) || (sex == "0" && age > 60);
    },
    // 判断获取背景图片样式
    getbgImgUrl(xxmm) {
      if (xxmm === "党员" || xxmm === "预备党员") {
        return require("@/assets/image/dy-red-btn.png");
      } else {
        return require("@/assets/image/dy-lan-btn.png");
      }
      // console.log("年龄、党龄、性别",age,partyAge,sex);
      // 女
      // if (sex == "1") {
      //   if (age > 50) {
      //     if (partyAge > 50) {
      //       return require("@/assets/image/dy-red-jinbian-btn.png");
      //     } else {
      //       return require("@/assets/image/dy-red-btn.png");
      //     }
      //   } else {
      //     return require("@/assets/image/dy-lan-btn.png");
      //   }
      // } else if (sex == "0") {
      //   // 男
      //   if (age > 60) {
      //     if (partyAge > 50) {
      //       return require("@/assets/image/dy-red-jinbian-btn.png");
      //     } else {
      //       return require("@/assets/image/dy-red-btn.png");
      //     }
      //   } else {
      //     if (partyAge > 50) {
      //       return require("@/assets/image/dy-lan-jinbian-btn.png");
      //     } else {
      //       return require("@/assets/image/dy-lan-btn.png");
      //     }
      //   }
      // }
    },
    getDangzhibuData() {
      // 所在党支部列表
      listDangzhibuData().then((res) => {
        this.dataOptions = res.rows;
        this.dzbuId = res.rows[0].gridId;
        const localId = localStorage.getItem("dzbuId");
        if (localId) {
          this.value = parseInt(localId);
          const netInfo = this.dataOptions.find((item) => item.gridId == localId);
          this.gridTitleInfo = netInfo;
        } else {
          this.value = res.rows[0].gridId;
          this.gridTitleInfo = res.rows[0];
        }
      });
      // 架构默认展示
      // localStorage.getItem("dzbuId");
      // console.log("获取默认架构ID", localStorage.getItem("dzbuId"));
      // this.dzbuId = localStorage.getItem("dzbuId");
      this.loading = true;
      if (localStorage.getItem("dzbuId")) {
        // getListDetail(localStorage.getItem("dzbuId")).then((res) => {
        //   this.dzbName = res.data.name;
        //   console.log("架构默认展示", res.data);
        //   console.log("党支部成立时间", res.data.setUpDate);
        //   this.setUpDate = res.data.setUpDate;
        //   this.value = res.data.name;
        // });
        getListjiagouDetail(localStorage.getItem("dzbuId")).then((res) => {
          console.log("获取网格列表", res);
          this.loading = false;
          this.dataFramework1 = [];
          this.dataFramework4 = [];
          res.rows.forEach((item) => {
            if (item.adminFlag === "1") {
              this.dataFramework1.push({
                ...item,
              });
            } else {
              this.dataFramework4.push({
                ...item,
              });
            }
          });
          console.log("网格一级数据", this.dataFramework1);
          // this.dataFramework2 = res.data[2];
          // this.dataFramework3 = res.data[3];
          // this.dataFramework4 = res.data[4];
        });
      } else {
        // getListDetail(this.dzbuId).then((res) => {
        //   this.dzbName = res.data.name;
        //   console.log("架构默认展示", res.data);
        //   console.log("党支部成立时间", res.data.setUpDate);
        //   this.setUpDate = res.data.setUpDate;
        // });
        getListjiagouDetail(this.dzbuId).then((res) => {
          console.log("获取网格列表", res);
          this.loading = false;
          this.dataFramework1 = [];
          this.dataFramework4 = [];
          res.rows.forEach((item) => {
            if (item.adminFlag === "1") {
              this.dataFramework1.push({
                ...item,
              });
            } else {
              this.dataFramework4.push({
                ...item,
              });
            }
          });
          // this.dataFramework2 = res.data[2];
          // this.dataFramework3 = res.data[3];
          // this.dataFramework4 = res.data[4];
        });
      }
    },
    // 架构查询
    onSubmit() {
      // getListDetail(localStorage.getItem("dzbuId")).then((res) => {
      //   this.dzbName = res.data.name;
      //   this.setUpDate = res.data.setUpDate;
      // });
      const id = localStorage.getItem("dzbuId");
      const netInfo = this.dataOptions.find((item) => item.gridId == id);
      this.gridTitleInfo = netInfo;
      console.log("网格信息", this.gridTitleInfo);
      this.loading = true;
      getListjiagouDetail(localStorage.getItem("dzbuId")).then((res) => {
        // console.log("点击查询架构", res.data[1]);
        this.loading = false;
        this.dataFramework1 = [];
        this.dataFramework4 = [];
        res.rows.forEach((item) => {
          if (item.adminFlag === "1") {
            this.dataFramework1.push({
              ...item,
            });
          } else {
            this.dataFramework4.push({
              ...item,
            });
          }
        });
        // this.dataFramework2 = res.data[2];
        // this.dataFramework3 = res.data[3];
        // this.dataFramework4 = res.data[4];
      });
    },
    // 党员详情弹框
    dialogVisiblebtn(item) {
      console.log("个人信息", item);
      this.dialogVisible = true;
      this.userInfo = item;
      // 获取
      // this.partyPayrollId = id;
      // console.log("党员个人ID", this.partyPayrollId);
      // this.dialogVisible = true;
      // // 个人信息
      // getListPersonDetail(id, unitId).then((res) => {
      //   this.dataMask = res.data;
      //   console.log("个人信息 性别", res.data.params.sex);
      //   //     addressInfo: "", //地址
      //   // phoneInfo: "", //联系电话
      //   // idInfo: "", //地址
      //   this.sex = res.data.params.sex;
      //   this.addressInfo = res.data.address.slice(0, 5) + "************";
      //   this.phoneInfo = res.data.phone.replace(
      //     /(\w{7})\w*(\w{0})/,
      //     "$1****$2"
      //   );
      //   this.idInfo = res.data.idCode.replace(
      //     /(\w{6})\w*(\w{0})/,
      //     "$1************$2"
      //   );
      // });
      // // 缴费查询
      // listPayment(id).then((res) => {
      //   this.dataPayment = res.rows;
      // });
      // // 积分查询
      // listIntegral(id).then((res) => {
      //   this.dataIntegral = res.rows;
      // });
      // // 打卡记录
      // listPunch(id).then((res) => {
      //   console.log("打卡记录", res.rows);
      //   this.dataPunch = res.rows;
      // });
    },
    // 跳转数据统计页面
    // ?'tab_active':'tab_default '
    handleCount(e) {
      this.$router.push({
        path: "/dataStatistics",
        query: {
          id: localStorage.getItem("dzbuId")
            ? localStorage.getItem("dzbuId")
            : this.dzbuId,
        },
      });
    },
    // 缴费
    onPayment() {
      listPayment(this.partyPayrollId, this.beginTime, this.endTime).then(
        (res) => {
          console.log("缴费查询", res);
          this.dataPayment = res.rows;
        }
      );
    },
    // 积分
    onInterger() {
      listIntegral(this.partyPayrollId, this.beginTime, this.endTime).then(
        (res) => {
          this.dataIntegral = res.rows;
        }
      );
    },
    // 打卡
    onDaka() {
      listPunch(this.partyPayrollId, this.beginTime, this.endTime).then(
        (res) => {
          this.dataPunch = res.rows;
        }
      );
    },
    handleClick() {},
    //
    changezhou(id) {
      localStorage.setItem("dzbuId", id);
      this.dzbuId = localStorage.getItem("dzbuId");
      console.log("获取下拉选择1", this.dzbuId);
      this.onSubmit();
    },
    // 打印时间
    getTime(date) {
      this.beginTime = date;
      console.log("打印时间1", date);
    },
    getTime2(date) {
      this.endTime = date;
      console.log("打印时间2", date);
    },
    handleClose(done) {
      done();
      this.dialogVisible = false;
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize); //每页下拉显示数据
    },
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      console.log(this.currentPage); //点击第几页
    },
    handleUserList() {
      // this.$http.get("http://localhost:3000/userList").then((res) => {
      //   //这是从本地请求的数据接口，
      //   this.userList = res.body;
      // });
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: auto;
  overflow: hidden;
  .back-ground-image {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  // top
  .member-top {
    width: 100vw;
    height: 100vh;
    // border: 1px solid tan;
    padding-top: 5vh;
    .member-top-1 {
      width: 100%;
      height: 6vh;
      line-height: 6vh;
      text-align: left;
      font-size: 0.9vw;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffd901;
      margin-top: -2.5vh;
      margin-left: 2.5vw;
    }
    .member-top-2 {
      width: 96%;
      height: 7vh;
      // border: 1px solid teal;
      margin: 1vh auto 0vh auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .el-form {
        ::v-deep .el-icon-arrow-up:before {
          content: "\e6e1";
          color: #bfdaff;
        }
        ::v-deep .el-select .el-input .el-input__inner {
          line-height: 5vh;
          outline: 0;
          padding: 0 15px;
          -webkit-transition: border-color 0.2s
            cubic-bezier(0.645, 0.045, 0.355, 1);
          background: transparent;
          border: 1px solid #2e55a3;
          // background: url(../../assets/image/dy-btn.png) center center no-repeat;
          // transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          width: 15vw;
          margin-right: 5px;
        }
        ::v-deep .el-button {
          height: 5vh;
          display: inline-block;
          line-height: 1;
          white-space: nowrap;
          cursor: pointer;
          // background: #fff url(../../../assets/image/dy-btn.png);
          background: url(../../assets/image/dy-btn.png) center center no-repeat;
          border: none;
          color: #7faaff;
          -webkit-appearance: none;
          text-align: center;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          outline: 0;
          margin: 0;
          -webkit-transition: 0.1s;
          transition: 0.1s;
          font-weight: 500;
          padding: 12px 20px;
          font-size: 14px;
          border-radius: 4px;
          // margin-left: 2vw;
        }
      }
      .member-top-2L {
        width: 100%;
        height: 7vh;
        // border: 1px solid teal;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // ::v-deep.el-icon-arrow-up:before {
        //   content: "\e6e1";
        //   color: #bfdaff;
        // }

        // ::v-deep.el-input__inner::placeholder {
        //   line-height: 4vh;
        //   outline: 0;
        //   padding: 0 15px;
        //   -webkit-transition: border-color 0.2s
        //     cubic-bezier(0.645, 0.045, 0.355, 1);
        //   transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        //   width: 17vw;
        // }
        // ::v-deep.el-button {
        //   height: 5vh;
        //   display: inline-block;
        //   line-height: 1;
        //   white-space: nowrap;
        //   cursor: pointer;
        //   // background: #fff url(../../../assets/image/dy-btn.png);
        //   background: url(../../assets/image/dy-btn.png) center center no-repeat;
        //   border: none;
        //   color: #7faaff;
        //   -webkit-appearance: none;
        //   text-align: center;
        //   -webkit-box-sizing: border-box;
        //   box-sizing: border-box;
        //   outline: 0;
        //   margin: 0;
        //   -webkit-transition: 0.1s;
        //   transition: 0.1s;
        //   font-weight: 500;
        //   padding: 12px 20px;
        //   font-size: 14px;
        //   border-radius: 4px;
        //   // margin-left: 2vw;
        // }
      }
      .member-top-2R {
        .dwjs-btn {
          width: 7vw;
          height: 4vh;
          display: inline-block;
          position: relative;
          top: -0.6vh;
          color: #d5f5fe;
          margin-right: 1vw;
          cursor: pointer;
          .dwjs-btn-1 {
            width: 7vw;
            height: 6vh;
            position: absolute;
            top: -0.4vw;
            left: 0vw;
          }
          .dwjs-btn-2 {
            width: 1.8vw;
            height: 1.8vw;
            position: absolute;
            top: 1vh;
            left: 0.5vw;
          }
          .dwjs-btn-4 {
            left: 1vw;
          }
          .dwjs-btn-3 {
            font-size: 0.8vw;
            position: absolute;
            left: 2.1vw;
            top: 2vh;
          }
          .dwjs-btn-5 {
            left: 2.6vw;
          }
        }
      }
    }
    .member-top-3 {
      width: 96%;
      height: 75vh;
      // border: 1px solid tan;
      margin: 0 auto;
      margin-top: 1vh;
      .member-top-3-1 {
        width: 100%;
        text-align: center;
        font-size: 1.6vw;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #30ecfb;
        text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);

        background: linear-gradient(
          0deg,
          #ffffff 0%,
          #ffffff 40%,
          #afafaf 60%,
          #ffffff 80%,
          #ffffff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 2vh;
      }
      .member-top-3-2 {
        width: 100%;
        text-align: center;
        font-size: 0.9vw;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #7faaff;
        text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.4);
        margin-top: 3vh;
      }
      .member-top-3-3 {
        width: 100%;
        height: auto;
        .member-jiagou {
          width: 90%;
          height: 58vh;
          // border: 1px solid tan;
          margin: 0 auto;
          margin-top: 4vh;
          overflow-y: auto;
          padding: 0 5%;
          .member-jiagou-1 {
            width: 75%;
            height: 11vh;
            // border: 1px solid thistle;
            margin: 0 auto;
            margin-top: 0.5vh;
            .member-jiagou-btn {
              width: 10.03vw;
              height: 8vh;
              // border: 1px solid thistle;
              margin: 0 auto;
              position: relative;
              .markIcon{
                position: absolute;
                right: 25px;
                top: 6px;
              }
              .member-img {
                width: 9.6vw;
                height: 7vh;
                position: absolute;
                left: 0vw;
                top: -0.5vh;
                z-index: -1;
              }
              .member-box {
                width: 96%;
                height: 6vh;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                font-size: 1vw;
                font-family: Microsoft YaHei;
                font-weight: bold;
                cursor: pointer;
                // padding-top: .8vh;
                .member-name0 {
                  color: #ff7f85;
                }
                .member-name1 {
                  color: #7faaff;
                }
                .member-name2 {
                  color: #d5f5fe;
                  margin-top: -0.5vh;
                }
              }
              .member-jiagou-gan {
                width: 2px;
                height: 3vh;
                background: #5384e5;
                // border: 2px solid #5384e5;
                position: absolute;
                top: 6.5vh;
                left: 5vw;
              }
            }
          }
          .member-jiagou-2 {
            width: max-content;
            height: 3vh;
            border: 2px solid #5384e5;
            border-bottom: none;
            margin: 0 auto;
            margin-top: 0vh;
            max-width: 92%;
            padding-left: 0.2vw;
            margin-bottom: 11vh;
            .member-jiagou-btn {
              float: left;
              margin-top: 3vh;

              // margin-left: 0.16%;
              // margin-right: 2.7%;
              // margin-right: 1.8%;
              &:first-child {
                float: left;
                // margin-left: 0.2%;
                // margin-right: 0.8%;
              }
              &:nth-of-type(4n + 0) {
                // float: right;
                margin-right: -1%;
              }
            }
          }
          .member-jiagou-3 {
            width: max-content;
            height: 3vh;
            border: 2px solid #5384e5;
            border-bottom: none;
            margin: 0 auto;
            // margin-top: 11vh;
            margin-top: -1.5vh;
            max-width: 92%;
            padding-left: 0.2vw;
            margin-bottom: 11vh;
            .member-jiagou-btn {
              float: left;
              margin-top: 3vh;

              // margin-left: 0.16%;
              // margin-right: 2.7%;
              // margin-right: 1.8%;
              &:first-child {
                float: left;
                // margin-left: 0.2%;
                // margin-right: 0.8%;
              }
              &:nth-of-type(4n + 0) {
                // float: right;
                margin-right: -1%;
              }
            }
          }
          .clear {
            clear: both;
          }
          .member-jiagou-4 {
            width: max-content;
            height: 3vh;
            border: 2px solid #5384e5;
            border-bottom: none;
            margin: 0 auto;
            margin-top: 0;
            max-width: 92.2%;
            padding-left: 0.2vw;
            .member-jiagou-btn {
              float: left;
              margin-top: 3vh;
              margin-right: -0.105%;

              &:first-child {
                float: left;
                margin-left: 0.2%;
                // margin-right: 0.8%;
              }
              &:nth-of-type(7n + 0) {
                // float: right;
                margin-right: -1%;
              }
            }
          }
        }
      }
    }
  }
}
	.el-button--primary {
		width: 68px;
		height: 38px;
		background: rgba(6, 83, 110, 0.4);
		border: 1px solid #2e55a3;
		box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
		font-size: 12px;
		font-family: SimSun;
		font-weight: 400;
		color: #7faaff;
		border-radius: 0px;
		&.other {
			clip-path: polygon(
				0 0,
				100% 0,

				100% 12px,
				100% calc(100% - 12px),
				calc(100% - 12px) 100%,
				12px 100%,

				0 100%,
				0 0
			);
			background: linear-gradient(-45deg, #2e55a3 8px, rgba(6, 83, 110, 0.4) 0) bottom right,
				linear-gradient(45deg, rgba(6, 83, 110, 0.4) 8px, rgba(6, 83, 110, 0.4) 0) bottom left,
				linear-gradient(135deg, rgba(6, 83, 110, 0.4) 8px, rgba(6, 83, 110, 0.4) 0) top left,
				linear-gradient(-135deg, rgba(6, 83, 110, 0.4) 8px, rgba(6, 83, 110, 0.4) 0) top right;

			background-size: 50% 51%;

			background-repeat: no-repeat;
		}
	}
::v-deep .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 99999;
  width: 50%;
  height: 31vh;
  // background: url();
  background: rgba(255, 255, 255, 0);
  z-index: 99999999;
  margin-top: 20vh !important;
  .el-dialog__headerbtn {
    width: 5vw;
    height: 7vh;
    // border: 1px solid teal !important;
    opacity: 0;
    position: absolute;
    top: 7vh;
    right: -2vw;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    z-index: 999999;
  }
  .back-ground-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 8vh;
    left: 1.2vw;
    // border: 1px solid tomato;
  }
  .back-ground-close {
    position: absolute;
    top: 6.3vh;
    right: -1.8vw;
  }
  .dialog-box {
    position: absolute;
    top: 10vh;
    left: 1.2vw;
    width: 98%;
    height: 28vh;
    overflow: hidden;
    // border: 1px solid tan;
    .dialog-box-top {
      width: 100%;
      height: 23vh;
      font-size: 0.9vw;
      font-family: Microsoft YaHei;
      // border: 1px solid thistle;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .dialog-box-top-L {
        width: 34%;
        height: 23vh;
        // border: 1px solid thistle;
        .tx-img {
          width: 6vw;
          height: 16vh;
          margin-left: 2.3vw;
          margin-top: 1.3vh;
        }
        .dialog-box-top-L2 {
          font-size: 1vw;
          text-align: center;
          font-weight: bold;
          color: #d5f5fe;
          padding: 1.5vh 0;
        }
        .dialog-box-top-L3 {
          font-size: 0.7vw;
          text-align: center;
          font-weight: bold;
          color: #d5f5fe;
          opacity: 0.6;
        }
      }
      .dialog-box-top-R {
        width: 78%;
        height: 23vh;
        margin-top: 1.3vh;
        // border: 1px solid thistle;
        .dialog-box-top-R-odd {
          width: 100%;
          height: 5vh;
          background: rgba(6, 83, 110, 0.4);
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #012552;
          .dialog-box-top-R-odd-L {
            width: 100%;
            height: 4.5vh;
            // border: 1px solid teal;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            span {
              font-size: 0.8vw;
              font-weight: bold;
              &:nth-of-type(1) {
                width: 20%;
                color: #5384e5;
                padding-left: 1vw;
              }
              &:nth-of-type(2) {
                width: 72%;
                font-weight: 400;
                color: #bfdaff;
              }
              &:nth-of-type(3) {
                width: 0.1vw;
                height: 0.5vh;
                background: #2e55a3;
              }
            }
          }
          .dialog-box-top-R-odd-R {
            width: 40%;
            height: 4.65vh;
            // border: 1px solid teal;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            span {
              font-size: 0.8vw;
              font-weight: bold;
              &:nth-of-type(1) {
                width: 64%;
                color: #5384e5;
                padding-left: 1vw;
              }
              &:nth-of-type(2) {
                width: 72%;
                font-weight: 400;
                color: #bfdaff;
              }
            }
          }
        }
        .dialog-box-top-R-even {
          width: 100%;
          height: 5vh;
          background: rgba(1, 49, 88, 1);
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #012552;
          .dialog-box-top-R-odd-L {
            width: 100%;
            height: 4.5vh;
            // border: 1px solid teal;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            span {
              font-size: 0.8vw;
              font-weight: bold;
              &:nth-of-type(1) {
                width: 20%;
                color: #5384e5;
                padding-left: 1vw;
              }
              &:nth-of-type(2) {
                width: 72%;
                font-weight: 400;
                color: #bfdaff;
              }
              &:nth-of-type(3) {
                width: 0.1vw;
                height: 0.5vh;
                background: #2e55a3;
              }
            }
          }
          .dialog-box-top-R-odd-R {
            width: 40%;
            height: 4.5vh;
            // border: 1px solid teal;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            span {
              font-size: 0.8vw;
              font-weight: bold;
              &:nth-of-type(1) {
                width: 45%;
                color: #5384e5;
                padding-left: 1vw;
              }
              &:nth-of-type(2) {
                font-weight: 400;
                color: #bfdaff;
              }
            }
          }
        }
      }
    }
    .dialog-box-bottom {
      width: 100%;
      height: 46vh;
      font-size: 0.9vw;
      font-family: Microsoft YaHei;
      // border: 1px solid thistle;
      margin-top: 0vh;
      .p-mulu-box {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        padding-top: 0px;
        .p-mulu-time {
          width: 100%;
          height: 8vh;

          .el-input__inner::placeholder {
            color: #bfdaff;
            text-align: center;
          }
          /* 谷歌 */
          .el-input__inner::-webkit-input-placeholder {
            color: #bfdaff;
            text-align: center;
          }
          /* 火狐 */
          .el-input__inner:-moz-placeholder {
            color: #bfdaff;
            text-align: center;
          }
          /*ie*/
          .el-input__inner:-ms-input-placeholder {
            color: #bfdaff;
            text-align: center;
          }
          .el-icon-date:before {
            content: "\e78e";
            color: #bfdaff;
          }

          form.el-form {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 1.7vh;
          }
          .el-input__inner {
            -webkit-appearance: none;
            background-color: rgba(255, 255, 255, 0);
            background-image: none;
            border-radius: 4px;
            border: 1px solid #dcdfe6;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            color: rgba(191, 218, 255, 1);
            display: inline-block;
            font-size: inherit;
            height: 40px;
            line-height: 40px;
            outline: 0;
            padding: 0 40px;
            -webkit-transition: border-color 0.2s
              cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            width: 100%;
            background: linear-gradient(
              270deg,
              rgba(16, 58, 112, 0),
              rgba(16, 58, 112, 0.24),
              rgba(35, 67, 173, 0.01)
            );
            border: 1px solid #0f2f6f;
          }
          .el-input__prefix {
            // left: -0.6vw;
            // -webkit-transition: all 0.3s;
            // transition: all 0.3s;
          }
          .el-col-2 {
            width: 8.33333%;
            text-align: center;
            color: rgba(191, 218, 255, 1);
          }
          .el-form-item__label {
            width: 8.5vw !important;
            text-align: center;
            color: #bfdaff;
          }
          .el-button {
            height: 5vh;
            display: inline-block;
            line-height: 1;
            white-space: nowrap;
            cursor: pointer;
            // background: #fff url(../../../assets/image/dy-btn.png);
            background: url(../../assets/image/dy-btn.png) center center
              no-repeat;
            border: none;
            color: #7faaff;
            -webkit-appearance: none;
            text-align: center;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            outline: 0;
            margin: 0;
            -webkit-transition: 0.1s;
            transition: 0.1s;
            font-weight: 500;
            padding: 12px 20px;
            font-size: 14px;
            border-radius: 4px;
            margin-left: -3vw;
          }
          span {
            // line-height: 8vh;
            // font-size: .85vw;
            // font-family: Microsoft YaHei;
            // font-weight: 400;
            // color: #bfdaff;
          }
        }
        .deit {
          margin-left: 1.5vw;
          .border-jifen {
            color: #ff752a;
          }
          .border-right {
            width: 0.1vw;
            height: 0.5vh;
            /* line-height: 5vh; */
            background: #2e55a3;
            margin-top: 1.2vh;
            float: right;
            /* margin-right: 0vw; */
            position: relative;
            display: inline-block;
            right: -0.5vw;
            z-index: 999999;
          }
          .el-table .cell {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            overflow: inherit;
            text-overflow: ellipsis;
            white-space: normal;
            word-break: break-all;
            line-height: 23px;
            padding-right: 10px;
          }
          .el-table th > .cell {
            display: inline-block;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            position: relative;
            vertical-align: middle;
            padding-left: 10px;
            padding-right: 10px;
            width: 100%;
            border-right: 1px solid #2e55a3;
          }
          .el-table th.el-table_1_column_5.is-center.is-leaf .cell,
          .el-table th.el-table_2_column_11.is-center.is-leaf .cell {
            border-right: none;
          }
          .el-table__empty-text {
            line-height: 60px;
            width: 50%;
            color: #bfdaff;
          }
          .el-pagination__total {
            margin-right: 10px;
            font-weight: 400;
            color: #cceeff;
          }
          [data-v-ddca6db6]
            .el-dialog
            .dialog-box
            .dialog-box-bottom
            .p-mulu-box
            .deit
            .el-input__inner {
            -webkit-appearance: none;
            background-color: rgba(0, 0, 0, 0);
            background-image: none;
            border-radius: 4px;
            border: 1px solid #2e55a3;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            color: #ffffff;
            display: inline-block;
            font-size: inherit;
            height: 40px;
            line-height: 40px;
            outline: 0;
            padding: 0 15px;
            -webkit-transition: border-color 0.2s
              cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            width: 100%;
          }
          .el-pagination {
            white-space: nowrap;
            padding: 2px 5px;
            color: #303133;
            font-weight: 700;
            float: right;
            margin-top: 1vh;
          }
          .el-table--border::after,
          .el-table--group::after,
          .el-table::before {
            content: "";
            position: absolute;
            background-color: rgba(0, 0, 0, 0);
            z-index: 1;
          }
          .el-input__inner::placeholder {
            color: #fff;
            text-align: center;
          }
          /* 谷歌 */
          .el-input__inner::-webkit-input-placeholder {
            color: #fff;
            text-align: center;
          }
          /* 火狐 */
          .el-input__inner:-moz-placeholder {
            color: #fff;
            text-align: center;
          }
          /*ie*/
          .el-input__inner:-ms-input-placeholder {
            color: #fff;
            text-align: center;
          }
          .el-pagination__jump {
            margin-left: 24px;
            font-weight: 400;
            color: #7faaff;
          }
          .el-pagination.is-background .btn-next.disabled,
          .el-pagination.is-background .btn-next:disabled,
          .el-pagination.is-background .btn-prev.disabled,
          .el-pagination.is-background .btn-prev:disabled,
          .el-pagination.is-background .el-pager li.disabled {
            color: #c0c4cc;
            background-color: rgba(6, 83, 110, 0.2);
            border: 1px solid #054d85;
            box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
          }
          .el-pagination.is-background .btn-next,
          .el-pagination.is-background .btn-prev,
          .el-pagination.is-background .el-pager li {
            margin: 0 5px;
            background-color: rgba(6, 83, 110, 0.4);
            color: #7faaff;
            min-width: 30px;
            border-radius: 2px;
          }
          .el-pagination.is-background .el-pager li:not(.disabled).active {
            // background: #06536e;
            background-image: url("~@/assets/image/btn-xz.png");
            color: #fff;
            opacity: 1;
          }

          .el-input__inner {
            -webkit-appearance: none;
            background-color: rgba(0, 0, 0, 0);
            background-image: none;
            border-radius: 4px;
            border: 1px solid rgba(46, 85, 163, 1);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            color: #ffffff;
            display: inline-block;
            font-size: inherit;
            height: 2.5vh;
            line-height: 2.5vh;
            outline: 0;
            padding: 0 15px;
            -webkit-transition: border-color 0.2s
              cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            width: 100%;
          }

          .el-table__body tr:hover > td {
            background-color: #00214a !important;
          }

          .el-table__body tr.current-row > td {
            background-color: #00214a !important;
          }
          .el-table td {
            border-bottom: 2px solid #00214a;
          }
          .el-table th.is-leaf {
            border-bottom: 2px solid #00214a;
          }
          .el-table td div {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            color: rgba(191, 218, 255, 1);
          }
          .el-table__body-wrapper {
            height: 20vh;
            overflow-x: hidden;
            overflow-y: auto;
            // overflow: auto;
            /* overflow: hidden; */
            position: relative;
            background-color: rgba(2, 46, 88, 1) !important;
          }
          .el-table thead {
            color: rgba(83, 132, 229, 1);
            font-weight: 500;
            background: tan;
          }
          .el-table th {
            background-color: rgba(2, 46, 88, 1) !important;
          }
          .el-table tr {
            // background-color: rgba(2, 46, 88, 1) !important;
            &:nth-child(odd) {
              background: #022e58;
            }
            &:nth-child(even) {
              background: #03375d;
            }
            &:hover {
              background: #03375d !important;
            }
          }
        }
        .el-tabs__nav-wrap {
          overflow: hidden;
          margin-bottom: -1px;
          position: relative;
          margin-top: 1.4vh;
        }
        .el-tabs__nav-wrap::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: rgba(255, 255, 255, 0);
          z-index: 1;
        }
        .el-tabs__item {
          color: rgba(191, 218, 255, 1);
          padding: 0;
        }
        .el-tabs__item.is-active {
          color: rgba(191, 218, 255, 1);
          background: rgba(12, 136, 134, 1);
        }
        .el-tabs__nav {
          white-space: nowrap;
          position: relative;
          transition: -webkit-transform 0.3s;
          -webkit-transition: -webkit-transform 0.3s;
          transition: transform 0.3s;
          transition: transform 0.3s, -webkit-transform 0.3s;
          transition: transform 0.3s, -webkit-transform 0.3s;
          float: left;
          z-index: 2;
          margin-left: 3vw;
          width: 51%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        div#tab-first {
          width: 10vw;
          height: 4vh;
          line-height: 4vh;
          text-align: center;
          border: 2px solid rgba(12, 136, 134, 1);
          // background: url("~@/assets/image/dy-jiaofei-1.png") 1.5vw center no-repeat;
          background-image: url("~@/assets/image/dy-jiaofei-1.png");
          background-position: 1.5vw center;
          background-repeat: no-repeat;
        }
        div#tab-second {
          width: 10vw;
          height: 4vh;
          line-height: 4vh;
          text-align: center;
          border: 2px solid rgba(12, 136, 134, 1);
          background-image: url("~@/assets/image/dy-jiaofei-2.png");
          background-position: 1.5vw center;
          background-repeat: no-repeat;
        }
        div#tab-third {
          width: 10vw;
          height: 4vh;
          line-height: 4vh;
          text-align: center;
          border: 2px solid rgba(12, 136, 134, 1);
          background-image: url("~@/assets/image/dy-jiaofei-3.png");
          background-position: 1.5vw center;
          background-repeat: no-repeat;
        }
        .el-tabs__active-bar {
          display: none;
        }
      }
    }
  }
}
</style>
